body,
html {
    margin: 0;
    font-family: 'Roboto, Noto, "Helvetica Neue", Arial, sans-serif';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 1.5em;
    height: 100%;
    width: 100%;
}

#root {
    height: 100%;
    width: 100%;
}

h2 {
    font-weight: 400;
    font-size: 1.5rem;
}

body.one-element-dragged {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.dragit-scrollbar ::-webkit-scrollbar {
    display: none;
    width: 0.3rem;
    height: 0.3rem;
}

.dragit-scrollbar.permanent ::-webkit-scrollbar {
    display: block;
}

.dragit-scrollbar:hover ::-webkit-scrollbar {
    display: block;
}

.dragit-scrollbar ::-webkit-scrollbar-track {
    border-radius: 0;
}

.dragit-scrollbar ::-webkit-scrollbar-thumb {
    border-radius: 0.2rem;
    background: rgba(0, 0, 0, 0.3);
    transition: all .2s;
}

.can-not-be-selected{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor:default;
  }
